<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="time-tracker-my-vacation-time"
              :hide-custom-filters="true"
              :hide-search-button="true"
              :show-labels="false"
              :compact="false"
              :loaded="filteringPanel.loaded"
              :filters="filteringPanel.filters"
              @change="onFilteringPanelChange"
              @search="onFilteringPanelSearch"
              @reset="onFilteringPanelReset"
              @loaded="onFilteringPanelLoad"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <table-custom
              ref="dataTable"
              :name="`custom_table_my_vacation_time`"
              :loading="this.isLoading"
              :data="dataTable.dataSet"
              :options="dataTable.options"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";
import TableCustom from "@/components/TableCustom";

export default {
  components: {
    FilteringPanel,
    TableCustom
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This month",
            title: "Period",
            name: "period"
          },
          {
            type: "select",
            dataType: "string",
            title: "Status",
            name: "status",
            trackby: "id",
            label: "name",
            options: [],
            selected: {}
          }
        ]
      },
      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Clock in date",
            "Clock in time",
            "Clock out date",
            "Clock out time",
            "Requested hours",
            "Unpaid hours",
            "Paid hours",
            "Status"
          ],
          totalColumns: [
            {
              name: "Paid hours",
              alias: "Paid vacation hours",
              method: "sum",
              style: {
                style: "decimal"
              }
            },
            {
              name: "Unpaid hours",
              alias: "Unpaid vacation hours",
              method: "sum",
              style: {
                style: "decimal"
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: true,
          showActions: false
        }
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFilteringPanelChange(e) {
      this.filteringPanel.selected = e;

      this.filterData(e);
    },
    onFilteringPanelSearch() {},
    onFilteringPanelReset() {},
    onFilteringPanelLoad() {
      this.getData();
    },
    filterData: function(e) {
      this.filteringPanel.selected = e;

      if (!this.rawData.length) return;

      let filteredData = this.rawData;

      if (this.filteringPanel.selected != undefined) {
        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.status
            ? i["Status"] === this.filteringPanel.selected.status.name
            : true
        );
      }

      if (this.filteringPanel.selected != undefined) {
        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected["period"]
            ? new Date(i["Clock in date"]) >=
                new Date(this.filteringPanel.selected["period"].startDate) &&
              new Date(i["Clock out date"]) <=
                new Date(this.filteringPanel.selected["period"].endDate)
            : true
        );
      }

      this.dataTable.dataSet = filteredData;
    },
    async getData() {
      this.isLoading = true;

      let response = await this.$api.get("timetracker/my-vacation-time");

      this.rawData = response;

      this.isLoading = false;

      this.filteringPanel.filters.find(
        f => f.name === "status"
      ).options = this.$helpers.getDistinctArray(
        this.rawData,
        "Status",
        "Status"
      );

      //this.filteringPanel.selected.status = { id: 'Approved', name: 'Approved'}

      this.filterData(this.$refs.filteringPanel.selected);
    }
  },
  watch: {}
};
</script>

<style></style>
